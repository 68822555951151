@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
    General rules to watch out for! The css needs to work in chrome 56 (2017) so some properties aren't supported:
    1. Text classes e.g text-white
    2. Background classes e.g bg-white
    3. grid gap classes e.g gap-4
*/

body {
    color: #020617;
    background: #F1F5F9;
    @apply text-xl;
}

* {
    user-select: none; /* Prevent text selection */
    -webkit-user-select: none; /* Disable on WebKit browsers */
    -ms-user-select: none; /* Disable on Internet Explorer */
}

.btn {
    @apply px-11 py-5 text-3xl flex rounded-md items-center font-bold justify-center leading-none;
}

.btn svg {
    @apply min-w-[32px] block;
}

.btn-danger {
    background: #F87171;
    color: #FFF;
}

.btn-body {
    background: #CBD5E1;
    color: #000;
}

.btn-black {
    background: #000;
    color: #fff;
}

.btn-small-spacing {
    @apply px-7 py-4 text-center;
}

.btn-text-large {
    @apply text-5xl;
}

.btn-small {
    @apply py-[21.5px] text-xl;
}

.btn-square {
    @apply rounded-none;
}

.tag {
    @apply py-1 px-4 rounded-full text-base font-bold flex items-center break-keep;
}

button {
    @apply bg-transparent outline-0;
}

select {
    @apply bg-white;
}

/* Sepcific Components */
.start-overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.62) 0%, rgba(0, 0, 0, 0) 100%);
}

.menu-scrollable-area {
    height: 1600px;
}

.menu-scrollable-area-with-total {
    height: calc(1600px - 201px);
}

.form-label {
    @apply mb-5 font-bold;
}

.form-input {
    @apply border border-black py-2 px-3;
}

/* 
    Custom Colours, added here instead of config as tailwind uses methods unsupported in older browsers
    E.g:
    .bg-limeGreen {
        --tw-bg-opacity: 1;
        background-color: rgb(216 243 240 / var(--tw-bg-opacity));
    }

    But it should output:

    .bg-limeGreen {
        --tw-bg-opacity: 1;
        background-color: rgb(216, 243, 240);
    }
 */
.bg-tag {
    background: #C4EDE9;
}

.text-tag {
    color: #1D625A;
}

.text-white {
    color: #FFFFFF;
}

.text-product-instruction {
    color: #334155;
}

.bg-menu {
    background: #F1F5F9;
}

.bg-limeGreen {
    background: #D8F3F0;
}

.bg-danger {
    background: #F87171;
}

.bg-success {
    background: #4ADE80;
}

.bg-warning {
    background: #FCD34D;
}

.bg-info {
    background: #22D3EE;
}

.bg-white {
    background: #FFFFFF;
}

.bg-slate-100 {
    background: #F1F5F9;
}

.bg-gray-100 {
    background: #f3f4f6;
}

.modal-overlay {
    background: rgba(0,0,0,.4)
}

.modal-height {
    max-height: calc(100dvh - 75px);
}

.border-toast {
    border-color: #CBD5E1;
}

input[type="checkbox"] {
    @apply w-10 h-10;
    border: 1px solid #CBD5E1;
}

.pinNumber {
    @apply w-full h-[100px] text-[60px] font-bold text-[#020617] rounded-sm border border-[#CBD5E1] flex items-center justify-center;
}

.text-error {
    @apply text-red-600;
}

/* Fade-Out Effect */
.fade-out {
    opacity: 0;
}

/* Slide-Out Effect */
.fade-out {
    transform: translateY(20px);
    opacity: 0;
}

.admin-setting-text {
    @apply min-w-[250px];
}

.scrollable-container {
    scrollbar-width: thin; /* Firefox */
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
}

.scrollable-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Handle color */
    border-radius: 4px;
}

.scrollable-container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Track color */
}